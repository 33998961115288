import React from 'react'
import PropTypes from 'prop-types'
import Axios from 'axios'

import { Player, PreloaderDefault } from '../components'

import { EventEmitter, CONSTANTS } from '../utils'

class VideoPlayer extends React.Component {

  state = {
    url: null,
    entry_id: null,
    partner_id: null,
    uiconf_id: null,
    ks: null,
    isLoading: false,
    videoStatus: null,
    player: null,
    isFullscreen: false,
    changeMedia: false,
    attempts: 0,
  }

  constructor(props) {
    super(props)

    this.setPlayer = this.setPlayer.bind(this)
    this.fetchVideoCredentials = this.fetchVideoCredentials.bind(this)
    this.renderPreloader = this.renderPreloader.bind(this)
    this.handleEvents = this.handleEvents.bind(this)
    // events
    this.playerReady = this.playerReady.bind(this)
    this.playerStateChange = this.playerStateChange.bind(this)
    this.firstPlay = this.firstPlay.bind(this)
    this.playerPlayEnd = this.playerPlayEnd.bind(this)
    this.playerUpdatePlayhead = this.playerUpdatePlayhead.bind(this)
    this.openFullScreen = this.openFullScreen.bind(this)
    this.closeFullScreen = this.closeFullScreen.bind(this)
    this.playerError = this.playerError.bind(this)
    this.mediaError = this.mediaError.bind(this)
    //functions
    this.seek = this.seek.bind(this)
    this.play = this.play.bind(this)
    this.pause = this.pause.bind(this)
    this.changeVolume = this.changeVolume.bind(this)
    this.seeked = this.seeked.bind(this)
    this.reloadPlayer = this.reloadPlayer.bind(this)
    this.events = new EventEmitter()
  }

  componentDidMount() {
    this.fetchVideoCredentials()
  }

  componentDidUpdate() {
    const { player } = this.state

    if (player) {
      this.handleEvents()
    }
  }

  componentWillUnmount() {
    this.events.off('playerReady')
    this.events.off('onProgress')
    this.events.off('firstPlay')
    this.events.off('playerStatus')
    this.events.off('seeked')
  }

  setPlayer(player) {
    this.setState({ ...this.state, player })
  }

  renderPreloader() {

    const { Preloader, width, height } = this.props

    return Preloader ? <Preloader /> : <PreloaderDefault width={width} height={height} />
  }

  async fetchVideoCredentials() {
    try {

      this.setState({ isLoading: true })
      const { decodeUrl } = this.props

      if (decodeUrl && decodeUrl !== '') {
        const { data } = await Axios({
          method: 'GET',
          url: decodeUrl,
        })

        const { entry_id, partner_id, uiconf_id, ks, embedPlayerUrl } = data
        const url = embedPlayerUrl ? embedPlayerUrl : CONSTANTS.URLS.KALTURA_IMPORTS.EMBED_PLAYER(partner_id, uiconf_id);
        this.setState({ entry_id, partner_id, uiconf_id, ks, url, isLoading: false })
      }
    } catch (error) {
      this.setState({ isLoading: false })
      this.reloadPlayer()
    }
  }

  handleEvents() {
    const { player } = this.state

    player.kBind('playerReady', this.playerReady)
    player.kBind('playerUpdatePlayhead', this.playerUpdatePlayhead)
    player.kBind('playerStateChange', this.playerStateChange)
    player.kBind('firstPlay', this.firstPlay)
    player.kBind('playerPlayEnd', this.playerPlayEnd)
    player.kBind('seeked', this.seeked)
    player.kBind('mediaError', this.mediaError)
    player.kBind('playerError', this.playerError)
  }

  //Player Events
  playerReady() {
    this.setState({ isLoading: false })

    const videoData = this.state.player.evaluate('{mediaProxy.entry}')

    this.events.emit('playerReady', {
      duration: videoData.duration,
      videoId: videoData.id,
      title: videoData.name,
      description: videoData.description,
      thumbnail: videoData.thumbnailUrl,
    })
  }

  playerStateChange(videoStatus) {
    if (videoStatus !== this.state.videoStatus) {
      this.setState({ videoStatus: videoStatus });
    }
    if (videoStatus === 'ready') {
      this.setState({ isLoading: false })
    }
    this.events.emit('playerStatus', videoStatus)
  }

  firstPlay() {
    const date = new Date()
    this.events.emit('firstPlay', date)
  }

  playerPlayEnd() {
    const date = new Date()
    this.events.emit('playerPlayEnd', date)
  }

  playerUpdatePlayhead(currentTime) {
    this.events.emit('onProgress', currentTime)
  }

  seeked(seekedTime) {
    this.events.emit('seeked', seekedTime)
  }

  playerError(error) {
    this.reloadPlayer()
  }

  mediaError(error) {
    this.reloadPlayer()
  }

  // player functions
  seek(time) {

    const { player } = this.state

    if (player) {
      const videoData = this.state.player.evaluate('{mediaProxy.entry}')

      let seekTime = 0

      if (time > 0) {
        seekTime = time < videoData.duration
          ? time
          : videoData.duration - 1
      } else {
        seekTime = time
      }

      player.sendNotification('doSeek', seekTime)
    }

  }

  play() {
    this.state.player && this.state.player.sendNotification('doPlay')
  }

  pause() {
    this.state.player && this.state.player.sendNotification('doPause')
  }

  closeFullScreen() {
    this.state.player && this.state.player.sendNotification('closeFullScreen')
  }

  openFullScreen() {
    this.state.player && this.state.player.sendNotification('openFullScreen')
  }

  reloadPlayer() {
    if (this.state.attempts <= 5) {
      this.setState({
        url: null,
        entry_id: null,
        partner_id: null,
        uiconf_id: null,
        ks: null,
        isLoading: false,
        videoStatus: null,
        player: null,
        isFullscreen: false,
        changeMedia: true,
        attempts: this.state.attempts += 1
      })
      this.fetchVideoCredentials()
    }
  }

  /**
   * @param {number} volume range 0 to 1
   */
  changeVolume(volume) {

    let volumeValue = 0

    if (volume >= 0) {
      volumeValue = volume > 1 ? 1 : volume
    }

    this.state.player && this.state.player.sendNotification('changeVolume', volumeValue)
  }

  render() {

    const {
      width,
      height,
      videoRef,
      watermark,
      watermarkTimeOn,
      watermarkTimeOff,
      imageUrl,
      offerUrl,
      Preloader,
      quizComponent,
      disableNativePlayerFullScreenControl
    } = this.props

    const {
      url,
      entry_id,
      partner_id,
      uiconf_id,
      ks,
      videoStatus,
      isLoading,
    } = this.state

    if (this.state.changeMedia && !this.state.isLoading && this.state.videoStatus === 'ready') {
      setTimeout(() => {
        this.state.player.sendNotification('doPlay')
        this.setState({ ...this.state, changeMedia: false, attempts: 0 })
      }, 500)
    }

    return (
      <main style={{ width, height }} >
        {(isLoading && url === null) ? this.renderPreloader() : (
         <Player
            url={url}
            watermark={watermark}
            watermarkTimeOn={watermarkTimeOn}
            watermarkTimeOff={watermarkTimeOff}
            offerUrl={offerUrl}
            imageUrl={imageUrl}
            entry_id={entry_id}
            partner_id={partner_id}
            uiconf_id={uiconf_id}
            ks={ks}
            width={width}
            height={height}
            setPlayer={this.setPlayer}
            isLoading={isLoading}
            videoStatus={videoStatus}
            Preloader={Preloader ? Preloader : PreloaderDefault}
            playerRef={videoRef}
            quizComponent={quizComponent}
            disableNativePlayerFullScreenControl={disableNativePlayerFullScreenControl}
          />
        )}
      </main>
    )
  }
}

VideoPlayer.defaultProps = {
  decodeUrl: '',
  width: '100%',
  height: '100%',
  watermark: '',
  watermarkTimeOn: 5000,
  watermarkTimeOff: 30000,
  videoRef: { current: null }
}

VideoPlayer.propTypes = {
  decodeUrl: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  watermark: PropTypes.string,
  videoRef: PropTypes.any,
  quizComponent: PropTypes.any
}

export default VideoPlayer
