import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { format, isFuture } from 'date-fns';
//material
import {
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField
} from "@mui/material";
import { Container as FormContainer } from './styles';
//components
import Modal from "src/components/Modal";
//api
import api from '../../../services/api';
//utils
import { courseSchemas, userSchemas } from "src/utils/schemas";
import Notify from "src/utils/notification";
import { ROOM_TECHNOLOGIES } from 'src/utils/constants';

// ----------------------------------------------------------------------
export default function CourseModal({ course, open, title, onSave, onClose, maxWidth = 'lg' }) {
  const [courseOwner, setCourseOwner] = useState('existent');
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchEmail, setSearchEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedInteractiveRoom, setIsCheckedInteractiveRoom] = useState(false);
  const [searchUserLoading, setSearchUserLoading] = useState(false);
  const [createUserLoading, setCreateUserLoading] = useState(false);
  const [subscriptionsCount, setSubscriptionsCount] = useState(0)

  const [values, setValues] = useState({
    courseId: course ? course.courseId : '',
    courseName: course ? course.courseName : '',
    duration: course ? course.duration : 0,
    extraUser: course ? course.extraUser : false,
    extraUserQty: course ? course.extraUserQty : 1,
    technology: course ? course.technology : '',
    maxClients: course ? course.maxClients : 1,
    interactive: course ? course.interactive : false,
  });

  const [valuesUser, setValuesUser] = useState({
    name: '',
    fullname: '',
    username: '',
    language: '',
    gender: '',
    password: '',
    dateOfBirth: format(new Date(), 'yyyy-MM-dd'),
    phone: '',
    showPassword: false,
  });

  useEffect(() => {
    if (values.technology === 0) {
      setValues({
        ...values,
        maxClients: 1
      })
    }
  }, [values.technology]);

  useEffect(() => {
    if (values?.technology === 1 && course) {
      api.course.getCourseInfo(course.courseId)
      .then(res => {
        setSubscriptionsCount(String(res.subscriptionsCount))        
      })
      .catch((err) => {
        Notify.error('Falha ao recuperar quantidade total de inscrições!');
      })
    }
  }, [values, course])

  const isNew = !course?.courseId;

  const getStatus = (error) => error.response ? error.response?.data?.error_description : '';

  const handleChangeOwner = (e) => {
    setCourseOwner(e.target.value);
    if (selectedUser) {
      setSelectedUser(null)
    }
  };

  const validation = () => {
    const courseData = {
      id: values?.courseId,
      name: values?.courseName,
      duration: values?.duration,
      technology: values?.technology,
      extraUser: values?.extraUser,
      extraUserQty: values?.extraUserQty
    }

    const userData = {
      name: valuesUser?.name,
      fullname: valuesUser?.fullname,
      username: valuesUser?.username,
      language: valuesUser?.language,
      gender: valuesUser?.gender,
      password: valuesUser?.password,
      dateOfBirth: valuesUser?.dateOfBirth,
      phone: Number(valuesUser?.phone)
    }

    const { error: courseError } = courseSchemas.create.validate(courseData);
    const { error: userError } = userSchemas.create.validate(userData);

    if (courseOwner === 'new') {
      return !!userError || !!courseError;
    }
    return !!courseError;
  }

  const validationSave = () => {
    if (values?.maxClients < 1) {
      setCreateUserLoading(false);
      return "Quantidade de participantes para Curso WebRTC deve ser maior que 0.";
    }

    if(values.technology === 1 && values?.maxClients < subscriptionsCount) {
      return "Quantidade máxima de participantes não pode ser menor que quantidade de participantes já matriculados.";
    }

    return false;
  }

  const handleSave = () => {
    setCreateUserLoading(true);

    const setValidationSave = validationSave();
    if (setValidationSave) {
      setCreateUserLoading(false);
      return Notify.error(setValidationSave);
    };

    if (courseOwner === 'new') {
      const userData = {
        name: valuesUser?.name,
        fullname: valuesUser?.fullname,
        username: valuesUser?.username,
        language: valuesUser?.language,
        gender: valuesUser?.gender,
        password: valuesUser?.password,
        dateOfBirth: valuesUser?.dateOfBirth,
        phone: Number(valuesUser?.phone)
      }

      const courseData = {
        courseId: values?.courseId,
        courseName: values?.courseName,
        duration: Number(values?.duration),
        courseTechnology: values?.technology,
        interactive: values?.interactive,
        maxClients: Number(values?.maxClients),
        courseOwnerUsername: valuesUser?.username,
        extraUserSupports: values?.extraUser,
        ...(values?.extraUser && { extraUserQty: Number(values?.extraUserQty) })
      }

      const { error: validation } = userSchemas.create.validate(userData);
      if (validation) {
        return Notify.error(validation.message);
      }

      if (isFuture(new Date(userData.dateOfBirth))) {
        setCreateUserLoading(false);
        return Notify.error('A Data de nascimento não pode ser maior que a data atual!');
      }

      api.user.create(userData)
        .then(res => {
          Notify.success('Usuário criado');
          setCreateUserLoading(false);
          onSave(courseData);
        })
        .catch(error => {
          if (error.response?.status === 409) {
            Notify.error('Já existe um usuário com o email inserido');
          } else {
            Notify.error(`Falha ao criar usuário. ${getStatus(error)}`);
          }
          setCreateUserLoading(false);
        });
    } else {
      if (isNew) {
        const courseData = {
          courseId: values?.courseId,
          courseName: values?.courseName,
          duration: Number(values?.duration),
          courseTechnology: values?.technology,
          interactive: values?.interactive,
          maxClients: Number(values?.maxClients),
          courseOwnerUsername: selectedUser?.username,
          extraUserSupports: values?.extraUser,
          ...(values?.extraUser && { extraUserQty: Number(values?.extraUserQty) })
        }

        setCreateUserLoading(false);
        onSave(courseData);
      } else {
        const courseData = {
          courseId: values?.courseId,
          courseName: values?.courseName,
          duration: Number(values?.duration),
          courseTechnology: Number(values?.technology),
          interactive: values?.interactive,
          maxClients: values?.maxClients
        }

        setCreateUserLoading(false);
        onSave(courseData);
      }

    }
  };
  
  const handleChangeCheckedInteractiveRoom = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
    setIsCheckedInteractiveRoom(!isCheckedInteractiveRoom);
  };

  const handleChangeCourse = (prop) => (event) => {
    if (event.target.name === 'extraUser') {
      setValues({ ...values, [prop]: event.target.checked });
      setIsChecked(!isChecked);
    }
    else if (event.target.id === 'extraUserQty') {
      if (event.target.value > 10) {
        return Notify.error("Máximo de 10 usuários extras.");
      } else {
        setValues({ ...values, [prop]: event.target.value });
      }
    }
    else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleChangeUser = (prop) => (event) => {
    setValuesUser({ ...valuesUser, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValuesUser({
      ...valuesUser,
      showPassword: !valuesUser.showPassword,
    });
  };

  const handleValidateExistentUser = () => {
    setSearchUserLoading(true);
    api.user.get(searchEmail)
      .then(res => {
        setSelectedUser(res)
        Notify.success('Usuário encontrado com sucesso!');
        setSearchUserLoading(false);
      })
      .catch(() => {
        Notify.warn('Usuário não encontrado!');
        setSearchUserLoading(false);
      });
  }

  const handleSelectClassCss = () => {
    if (selectedUser && (values?.technology === 1)) {
      return 'increase-heigh-selected-tec'
    }
    else if (!selectedUser && (values?.technology === 1)) {
      return 'increase-heigh-new-tec'
    }
    else if (selectedUser && (values?.technology === 0)) {
      return 'increase-heigh-selected'
    }
    else {
      return 'increase-heigh-new'
    }
  }

  return (
    <Modal open={open} title={title} onClose={onClose} maxWidth={maxWidth}>
      <FormContainer>
        <div className='form'>
          {title === 'Criar Curso' && (
            <p>Formulário de criação de curso</p>
          )}
          <TextField sx={{ m: 1 }}
            disabled={!isNew}
            id="courseId"
            label="Id do Curso"
            type="text"
            value={values?.courseId}
            onChange={handleChangeCourse('courseId')}
            fullWidth />

          <TextField sx={{ m: 1 }}
            disabled={!isNew}
            id="courseName"
            label="Nome"
            type="text"
            value={values?.courseName}
            onChange={handleChangeCourse('courseName')}
            fullWidth />

          <TextField sx={{ m: 1 }}
            id="duration"
            label="Duração (Em dias, 0 para indeterminado.)"
            type="number"
            value={values?.duration || 0}
            onChange={handleChangeCourse('duration')}
            fullWidth />

          <FormControl sx={{ m: 1 }} fullWidth>
            <InputLabel id="technology-select-label">Tecnologia</InputLabel>
            <Select
              labelId="technology-select-label"
              id="technology-select"
              value={values?.technology}
              label="Tecnologia"
              onChange={handleChangeCourse('technology')}>
              <MenuItem value={0}>Kaltura</MenuItem>
              <MenuItem value={1}>WebRTC</MenuItem>
            </Select>
          </FormControl>
          {(values?.technology === 1) &&
            <FormControl fullWidth sx={{ m: 1}} >
            <div className='row-max-participants'>
              <TextField
                id="maxClients"
                label="Quantidade Max. de participantes"
                type="number" value={values?.maxClients}
                onChange={handleChangeCourse('maxClients')}
                fullWidth
                data-testid="textfield-technology-webrtc" />
              {!isNew ? (
                <TextField
                label="Participantes já matriculados"
                type="text"
                disabled
                value={subscriptionsCount || 'Carregando...'}
                fullWidth
                data-testid="textfield-subscription-count" />
              ) : ""}
            </div>
            </FormControl>
          }

          {values?.technology === ROOM_TECHNOLOGIES.Webrtc &&
            <FormGroup sx={{ m: 1 }}>
            <InputLabel shrink >Habilitar sala interativa</InputLabel>
            <FormControlLabel
              control={
                <Switch
                  name='checkedInteractiveRoom'
                  checked={values?.interactive}
                  onChange={handleChangeCheckedInteractiveRoom('interactive')}
                  value={values?.interactive}
                />}
              label={values?.interactive ? 'Habilitado' : 'Desabilitado'}
            />
          </FormGroup>
          }
          <div className='row-max-user'>
            {isNew && (
              <FormGroup sx={{ m: 1 }}>
                <InputLabel shrink >Habilitar acesso a usuário extra</InputLabel>
                <FormControlLabel
                  control={
                    <Switch
                      name='extraUser'
                      checked={isChecked}
                      onChange={handleChangeCourse('extraUser')}
                      value={isChecked}
                    />}
                  label={isChecked ? 'Habilitado' : 'Desabilitado'}
                />
              </FormGroup>
            )}
            {isChecked && (
              <TextField
                sx={{ m: 1 }}
                InputProps={{ inputProps: { min: 1, max: 10 } }}
                id="extraUserQty"
                label="Informe o nº máximo de usuários extras"
                type="number"
                value={values?.extraUserQty}
                onChange={handleChangeCourse('extraUserQty')}
                fullWidth />
            )}
          </div>
          {(isNew && courseOwner === 'existent') && (
            <>
              <FormGroup>
                <TextField
                  sx={{ m: 1 }}
                  type="email"
                  label="Validar proprietário ( e-mail )"
                  name='email' value={searchEmail}
                  onChange={e => setSearchEmail(e.target.value)}
                  fullWidth />
              </FormGroup>
              <Button
                sx={{ m: 1 }}
                onClick={handleValidateExistentUser}
                variant="contained"
                disabled={!searchEmail || searchUserLoading}
                color='primary'
                fullWidth
                data-testid="button-edit-course"
              >
                {searchUserLoading ? 'Aguarde...' : 'Validar'}
              </Button>
            </>
          )}
          {isNew && (
            <RadioGroup aria-label="courseOwner" value={courseOwner} onChange={handleChangeOwner} row  >
              <FormControlLabel value="existent" control={<Radio color='primary' />}
                label="Proprietário existente" />
              <FormControlLabel value="new" control={<Radio color='primary' />}
                label="Criar novo proprietário" />
            </RadioGroup>
          )}
          {title === 'Criar Curso' && (
            <Button
              sx={{ m: 1 }}
              variant="contained"
              disabled={validation() || (createUserLoading || (courseOwner === 'existent' ? !selectedUser?.username : false))}
              onClick={() => handleSave()}
              fullWidth
            >
              {createUserLoading ? 'Aguarde...' : `${title}`}
            </Button>
          )}
          {title === 'Editar Curso' && (
            <Button
              sx={{ m: 1 }}
              variant="contained"
              disabled={createUserLoading}
              onClick={() => handleSave()}
              fullWidth
            >
              {createUserLoading ? 'Aguarde...' : `${title}`}
            </Button>
          )}

        </div>
        {(courseOwner === 'new' || !!selectedUser) && (
          <div className={`form ${handleSelectClassCss()}`}>
            <p>Dados do Proprietário</p>
            <div className='row'>
              <TextField sx={{ m: 1 }}
                disabled={!!selectedUser}
                id="nameUser"
                label="Nome *"
                type="text"
                value={selectedUser?.name || valuesUser?.name}
                onChange={handleChangeUser('name')}
                fullWidth
              />
              <TextField sx={{ m: 1 }} disabled={!!selectedUser} id="fullnameUser" label="Nome Completo *" type="text" value={selectedUser?.fullname || valuesUser?.fullname} onChange={handleChangeUser('fullname')} fullWidth />
            </div>
            <div className='row'>
              <TextField sx={{ m: 1 }} disabled={!!selectedUser} id="emailUser" label="E-mail *" type="email" value={selectedUser?.username || valuesUser?.username} onChange={handleChangeUser('username')} fullWidth />
              <TextField sx={{ m: 1 }} disabled={!!selectedUser} id="phoneUser" label="Telefone *" type="number" value={selectedUser?.phone || valuesUser?.phone} onChange={handleChangeUser('phone')} fullWidth />
            </div>
            {
              (courseOwner === 'new' && !selectedUser) && (

                <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">Senha (min. 8 caracteres)</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={valuesUser.showPassword ? 'text' : 'password'}
                    value={valuesUser.password}
                    onChange={handleChangeUser('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={event => event.preventDefault()}
                          edge="end"
                        >
                          {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Senha (min. 8 caracteres)"
                  />
                </FormControl>

              )
            }
            <div className='row'>
              <FormControl sx={{ m: 1 }} fullWidth>
                <InputLabel id="language-select-label">Idioma</InputLabel>
                <Select
                  labelId="language-select-label"
                  id="language-select"
                  value={selectedUser?.language || valuesUser?.language}
                  label="Idioma"
                  onChange={handleChangeUser('language')}
                  disabled={!!selectedUser}
                >
                  <MenuItem sx={{ color: '#000' }} value={'pt-BR'}>Português</MenuItem>
                  <MenuItem value={'en-US'}>Inglês</MenuItem>
                  <MenuItem value={'es-ES'}>Espanhol</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} fullWidth>
                <InputLabel id="gender-select-label">Gênero</InputLabel>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  value={selectedUser?.gender || valuesUser?.gender}
                  label="Gênero"
                  onChange={handleChangeUser('gender')}
                  disabled={!!selectedUser}
                >
                  <MenuItem value={'M'}>Masculino</MenuItem>
                  <MenuItem value={'F'}>Feminino</MenuItem>
                  <MenuItem value={'X'}>Não Informar</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                <InputLabel shrink id="dateOfBirth">Data de Nascimento</InputLabel>
                <TextField
                  name="dateOfBirth"
                  type="date"
                  defaultValue={selectedUser?.dateOfBirth || valuesUser?.dateOfBirth}
                  onChange={handleChangeUser('dateOfBirth')}
                  disabled={!!selectedUser}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: format(new Date(), 'yyyy-MM-dd')
                  }}
                />
              </FormControl>
            </div>
          </div>
        )}
      </FormContainer>
    </Modal>
  );
}