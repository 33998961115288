import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types'

import { useScript } from '../../hooks'
import { container, watermarkstyle, imageStyle, linkStyle } from './styles'

function Player({
  url,
  watermark,
  watermarkTimeOn,
  watermarkTimeOff,
  imageUrl,
  offerUrl,
  entry_id,
  partner_id,
  uiconf_id,
  ks,
  width,
  height,
  setPlayer,
  playerRef,
  isLoading,
  videoStatus,
  Preloader,
  quizComponent,
  disableNativePlayerFullScreenControl = false
}) {
  const [loaded] = useScript(url)
  const [showWatermark, setShowWatermark] = useState(false);
  const [idInterval, setIdInterval] = useState();
  const [topWatermark, setTopWatermark] = useState(0);
  const [leftWatermark, setLeftWatermark] = useState(0);
  const [bottomOfferImage, setBottomOfferImage] = useState(0);
  const [leftOfferImage, setLeftOfferImage] = useState(0);
  const [widthWindow, heightWindow] = useWindowSize();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [beforeVideoStatus, setBeforeVideoStatus] = useState();
  const [offerWidth, setOfferWidth] = useState(0);
  const fullScreenIOSStateRef = useRef({ isFullScreen: true })

  function playerCallback(playerId) {
    const player = document.getElementById(playerId)
    player.kBind('onToggleFullscreen', toggleFullscreen)
    setPlayer(player)
  }

  function isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }


  function toggleFullscreen() {

    if (isIOS()) {
      const player = document.getElementById('player')
      const playerWrapper = document.getElementById('player-container')
      fullScreenIOSStateRef.current = { ...fullScreenIOSStateRef.current, playerWrapperStyle: playerWrapper.style }

      if (fullScreenIOSStateRef.current.isFullScreen) {
        playerWrapper.style.position = 'fixed'
        playerWrapper.style.top = 0
        playerWrapper.style.right = 0
        playerWrapper.style.left = 0
        playerWrapper.style.bottom = 0
        playerWrapper.style.zIndex = 9999
      } else {
        playerWrapper.style = fullScreenIOSStateRef.current.playerWrapperStyle
        player.style.position = 'absolute'
        player.style.height = '100%'
      }

      return fullScreenIOSStateRef.current.isFullScreen = !fullScreenIOSStateRef.current.isFullScreen
    }

    const isFullScreenMode = document.fullScreen
      || document.fullscreenElement
      || document.mozFullScreen
      || document.webkitIsFullScreen;

    setIsFullScreen(!!isFullScreenMode)

    if (!isFullScreenMode) {
      if (watermark || offerUrl) {
        launchIntoFullscreen(document.getElementById('player-container'));
      }
    } else {
      exitFullScreen();
    }
  }

  function launchIntoFullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  function exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.webkitSupportsFullscreen) {
      document.webkitExitFullscreen();
    }
  }

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useEffect(() => {
      window.addEventListener('load', function (event) {
        new ResizeObserver((container) => {
          setSize([container[0].contentRect.width, container[0].contentRect.height])
        }).observe(document.getElementById('player-container'))
      }())
    }, []);
    return size;
  }

  useEffect(() => {
    if (loaded) {
      const config = {
        'targetId': 'player',
        'wid': `_${partner_id}`,
        'uiconf_id': uiconf_id,
        'entry_id': `${entry_id}`,
        'flashvars': {
          'ks': `${ks}`
        },
        'playback': {
          'autoPlay': false,
          'automute': false,
        },
        'readyCallback': playerCallback
      }
      window.kWidget.embed(config)
      mw.setConfig('EmbedPlayer.ExternalFullScreenControl', disableNativePlayerFullScreenControl);
    }

  }, [loaded, partner_id, uiconf_id, ks, entry_id])

  useEffect(() => {
    if ((videoStatus === 'playing') && watermark) {
      let watermark = () => {
        if (!showWatermark) {
          const rect = document.getElementById('player') ?
            document.getElementById('player').getBoundingClientRect() :
            '';
          const randomTop = Math.random() * rect.height;
          const randomLeft = Math.random() * rect.width;
          const topCalc = rect ? (randomTop + 20 >= rect.height ? randomTop + rect.top - 40 : randomTop) : 0;
          const leftCalc = rect ?
            (randomLeft + 350 >= rect.width ?
              randomLeft - (randomLeft + 350 - (rect.width + rect.left)) :
              randomLeft + rect.left) :
            rect.left;
          setTopWatermark(topCalc)
          setLeftWatermark(leftCalc)
        }
        setShowWatermark(true);
      };
      const currentIdInterval = setInterval(watermark, watermarkTimeOff);
      setIdInterval(currentIdInterval);
    } else if (watermark && (beforeVideoStatus !== 'playing')) {
      clearInterval(idInterval);
      setIdInterval();
    }
    setBeforeVideoStatus(videoStatus)
  }, [videoStatus])

  useEffect(() => {
    if (showWatermark && watermark) {
      setTimeout(() => {
        setShowWatermark(false);
      }, watermarkTimeOn);
    }
  }, [showWatermark])

  useEffect(() => {
    if (offerUrl) {
      const rect = document.getElementById('player') ?
        document.getElementById('player').getBoundingClientRect() :
        '';
      const width = rect.width * 0.5625 > rect.height ? rect.height * 0.18 : rect.width / 8;
      if (rect.width * 0.5625 >= rect.height) {
        // 1.78 - constant for aspect ratio (for height)
        setLeftOfferImage(((rect.width - (rect.height * 1.78)) / 2) + (rect.height / 11) + 25);
        //setBottomOfferImage(rect.height / 10);
        setBottomOfferImage((rect.height / 13) + 30)
      } else {
        // 0.5625 - constant for aspect ratio 16:9
        setBottomOfferImage(7 + ((rect.height - (rect.width * 0.5625)) / 2) + (70 * (rect.width / 1170)));
        // 35 - value of player controls height
        // 835 - half of min width and max width (1170 + 500)
        setLeftOfferImage(5 + (28 * (rect.width / 835)));

      }
      setOfferWidth(width)
    }
  }, [offerUrl, widthWindow, heightWindow, isFullScreen])

  return (
    <main id="player-container" style={{ width, height, position: 'relative' }}>
      {quizComponent && quizComponent}
      {offerUrl ? <a style={linkStyle(leftOfferImage, bottomOfferImage)} target="_blank" href={offerUrl}>
        <img src={imageUrl} style={imageStyle(offerWidth)}></img>
      </a> : ''}
      <h6 id="watermark" style={watermarkstyle(topWatermark, leftWatermark)}>
        {watermark && showWatermark ? watermark : ''}
      </h6>
      <div ref={playerRef} id='player' style={container(width, height)} />
    </main>
  )
}

Player.defaultProps = {
  entry_id: '',
  partner_id: '',
  uiconf_id: '',
  ks: '',
  width: '100%',
  height: '100%',
  isLoading: true,
  imageUrl: '',
  offerUrl: '',
  disableNativePlayerFullScreenControl: false,
}

Player.propTypes = {
  entry_id: PropTypes.string.isRequired,
  partner_id: PropTypes.number.isRequired,
  uiconf_id: PropTypes.string.isRequired,
  ks: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  isLoading: PropTypes.bool,
  offerUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  disableNativePlayerFullScreenControl: PropTypes.bool,
  //actions
  setPlayer: PropTypes.func,
}

export default Player
