import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { filter } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { fetchEditRoom, roomIsLive } from '../../store/modules/rooms/actions';
import { styled } from '@mui/material/styles';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
} from '@mui/material';
import keypadFill from '@iconify/icons-eva/keypad-fill';
import listFill from '@iconify/icons-eva/list-fill';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import Image from 'src/components/Image';
import {
    LiveListHead,
    LiveListToolbar
} from '../../components/_dashboard/live';
// actions
import { getLives } from '../../store/modules/live/actions';
import DropdownTableMenu from 'src/components/DropdownTableMenu';


const TABLE_HEAD = [
    { id: 'image', label: 'Thumbnail', alignRight: false },
    { id: 'name', label: 'Live', alignRight: false },
    { id: 'courseId', label: 'Curso', alignRight: false },
    { id: 'technology', label: 'Tecnologia', alignRight: false },
    { id: 'startDate', label: 'Horário de início da live', alignRight: false },
    { id: 'participants', label: 'Participantes', alignRight: false }
];

// ----------------------------------------------------------------------

const StyledTableRow = styled(TableRow)(() => ({
    [`&.MuiTableRow-hover`]: {
        cursor: 'pointer'
    },
}));


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_live) => _live.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}



export default function Live() {
    const dispatch = useDispatch();

    const { list: LIVELIST } = useSelector(state => state.live);

    const history = useHistory();

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        dispatch(getLives());
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleEditHour = (data) => {
        const editHour = new Date(data);
        const hours = (`${editHour.getUTCHours()}`).length === 2 ? editHour.getUTCHours() : `0${editHour.getUTCHours()}`;
        const minutes =  (`${editHour.getUTCMinutes()}`).length === 2 ? editHour.getUTCMinutes() : `0${editHour.getUTCMinutes()}`;
        return `${hours}:${minutes}`
    }

    const handleGoToVideoDetails = (row) => {
        const roomId = row.roomId;
        dispatch(fetchEditRoom(roomId));
        dispatch(roomIsLive(roomId));
        history.push(`/lives/${roomId}/details`);
    }
   
   const handleGoToPresenceReport = (row) => {
        const {roomId, courseId} = row;
        history.push(`/presence-report?course=${roomId}?channelId=${courseId}`);
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - LIVELIST.length) : 0;

    const filteredLives = applySortFilter(LIVELIST, getComparator(order, orderBy), filterName);

    const isLiveNotFound = filteredLives.length === 0;

    const buildOptions = useCallback((row) => {
        const options = [
            {
                label: 'Dashboard',
                icon: keypadFill,
                action: () => handleGoToVideoDetails(row)
            },        
            {
                label: 'Lista de Presença',
                icon: listFill,
                action: () => handleGoToPresenceReport(row)
            },        
        ]
        return options
    }, [])


    return (
        <Page title="Lives">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Lives
                    </Typography>
                </Stack>
                <Card>
                    <LiveListToolbar
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <LiveListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {filteredLives
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            const { image, name, courseId, technology, startDate, users } = row;
                                            return (
                                                <StyledTableRow
                                                    hover
                                                    key={name}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                >
                                                    <TableCell align="center">
                                                        <Image image={image} width={120} height={120} />
                                                    </TableCell>
                                                    <TableCell align="left">{name}</TableCell>
                                                    <TableCell align="left">{courseId}</TableCell>
                                                    <TableCell align="left">{technology === 0 ? 'Kaltura' : 'WebRTC'}</TableCell>
                                                    <TableCell align="center">{handleEditHour(startDate)}</TableCell>
                                                    <TableCell align="center">{users}</TableCell>
                                                        <TableCell align="right">
                                                            <DropdownTableMenu options={buildOptions(row)}  />
                                                    </TableCell>                                                    
                                                </StyledTableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isLiveNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={LIVELIST.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    )
}