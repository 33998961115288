import { useEffect, useState } from 'react'

let cachedScripts = null
export default function useScript(url, isAsync = false) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false
  })

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts === url) {
        setState({
          loaded: true,
          error: false
        })
      } else {
        cachedScripts = url

        // Remove old script
        const oldScript = document.getElementById("tokyo-script");
        if (oldScript) {
          oldScript.remove()
        }

        // Create script
        let script = document.createElement('script')
        script.src = url
        script.id = 'tokyo-script'
        script.async = isAsync

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false
          })
        }

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(url)
          if (index >= 0) cachedScripts.splice(index, 1)
          script.remove()

          setState({
            loaded: true,
            error: true
          })
        }

        script.addEventListener('load', onScriptLoad)
        script.addEventListener('error', onScriptError)

        // Add script to document body
        document.head.appendChild(script)

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener('load', onScriptLoad)
          script.removeEventListener('error', onScriptError)
        }
      }
    },
    [url, isAsync] // Only re-run effect if script src changes
  )

  return [state.loaded, state.error]
}
