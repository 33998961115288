/**
 * @param { string } width 
 * @param { string } height 
 * @returns { object }
 */
const container = (width, height) => ({
    width,
    height,
    position: 'relative',
})

const watermarkstyle = (top, left) => ({ 
    color: 'white',
    opacity: '0.15',
    position: 'absolute',
    height: '14px',
    top,
    left,
    maxWidth: '350px',
    overflow: 'hidden',
    zIndex: 2
})

const linkStyle = (left, bottom) => ({ 
    backgroundColor: 'white',
    position: 'absolute',
    bottom: bottom,
    left: left,
    zIndex: 2
})

const imageStyle = (width) => ({ 
    width: width,
})

export {
    container,
    watermarkstyle,
    linkStyle,
    imageStyle
}