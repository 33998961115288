import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import {
  CourseListHead,
  CourseListToolbar,
  CourseMoreMenu,
  CourseModal
} from '../../components/_dashboard/course';

// actions
import { getCourses, editCourse, createCourse } from '../../store/modules/course/actions';
import { useBreadcrumbs } from 'src/hooks/useBreadcrumbs';
import Breadcrumbs from 'src/components/Breadcrumbs';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'courseId', label: 'Id', alignRight: false },
  { id: 'courseName', label: 'Nome do Curso', alignRight: false },
  { id: 'duration', label: 'Duração', alignRight: false },
  { id: 'technology', label: 'Tecnologia', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    const filterId = filter(array, (_course) => _course.courseId.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    const filterName = filter(array, (_course) => _course.courseName.toLowerCase().indexOf(query.toLowerCase()) !== -1);

    if (filterId.length) {
      return filterId
    }
    else if (filterName.length) {
      return filterName
    }
    else {
      return []
    }
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Course() {

  const dispatch = useDispatch();

  const history = useHistory();

  const { list: COUSERLIST } = useSelector(state => state.course);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('courseName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { breadcrumbs, setItems } = useBreadcrumbs();


  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const breadcrumbsItems = [
      { to: `/courses`, label: "Cursos" },
    ];
    setItems(breadcrumbsItems);
  }, [setItems]);

  useEffect(() => {
    dispatch(getCourses());
  }, []);

  const handleSaveCourse = (courseData) => {

    const { courseId, courseName, duration, courseTechnology, interactive, maxClients } = courseData;

    if (!selectedCourse) {
      dispatch(createCourse(courseData));
    } else {
      dispatch(editCourse(courseId, courseName, duration, courseTechnology, interactive, maxClients));
    }
    handleCloseModal();
  }

  const handleEditCourse = (course) => {
    setSelectedCourse(course);
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setSelectedCourse(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handlePresenceReport = (row) => {
      history.push(`/courseVideos/${row.courseId}`);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - COUSERLIST.length) : 0;

  const filteredCourses = applySortFilter(COUSERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredCourses.length === 0;

  return (
    <Page title="Courses">
      <Container>
      <Breadcrumbs items={breadcrumbs} />

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Lista de Cursos
          </Typography>
          <Button
            variant="contained"
            component="button"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => setIsOpenModal(true)}
          >
            Novo Curso
          </Button>
        </Stack>
        <Card>
          <CourseListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            caller='course'
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CourseListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredCourses
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { courseId, courseName, duration, technology, maxClients } = row;
                      return (
                        <TableRow
                          hover
                          key={courseId}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell align="left">{courseId}</TableCell>
                          <TableCell align="left">{courseName}</TableCell>
                          <TableCell align="left">{duration}</TableCell>
                          <TableCell align="left">{!technology ? 'Kaltura' : `WebRTC ( ${maxClients} )`}</TableCell>
                          <TableCell align="right">
                            <CourseMoreMenu
                              onClick={() => handlePresenceReport(row)}
                              onEdit={() => handleEditCourse(row)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={COUSERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {isOpenModal && (
        <CourseModal
          course={selectedCourse}
          open={isOpenModal}
          title={`${selectedCourse ? 'Editar' : 'Criar'} Curso`}
          maxWidth='lg'
          onSave={courseData => handleSaveCourse(courseData)}
          onClose={() => handleCloseModal()}
        />
      )}
    </Page>
  );
}
