class EventEmitter {
  constructor() {
    this.events = {}
    
    this.on = this.on.bind(this)
    this.emit = this.emit.bind(this)
    this.off = this.off.bind(this)
  }

  on(eventName, callback) {
    if(this.events[eventName]) {
        this.events[eventName].push(callback)
    } else {
      this.events[eventName] = [callback]
    }
  }

  emit(eventName, ...payload) {
    if(this.events[eventName]) {
      this.events[eventName].forEach(callback => callback.apply(null, payload))
    }
  }

  off(eventName) {
    delete this.events[eventName]
  }
}

export default EventEmitter